import React from "react";
import '../../style/phytobloom/youtube.css';


export default function SectionYoutube() {


  return (
    <>

      <section className="video">
        <h1>Plataforma Phytobloom</h1>
        <p><iframe width="90%" height="800px" src="https://www.youtube.com/embed/QWzZbOT9eRw?si=doZaJVB7qHK8pK17" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></p>
        <h1>Veja uma demostração</h1>
      </section>

    </>
  )
}